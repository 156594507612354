import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'


import './../css/main.css'

const Layout = ({ children }) => {


  useEffect(() => {
    // setCanvas()
  }, [])


  // let setCanvas = () => {
  //   let canvas = document.getElementById('canvas');
  
  //   let winMin = Math.min(window.innerWidth,window.innerHeight);
  //   canvas.width = winMin;
  //   canvas.height = winMin;
  
  //   let ctx = canvas.getContext('2d');
  
  //   /* globalCompositeOperation :
  //     normal | multiply | screen | overlay | 
  //     darken | lighten | color-dodge | color-burn | hard-light | 
  //     soft-light | difference | exclusion | hue | saturation | 
  //     color | luminosity
  //   */
  
  
  //   ctx.globalCompositeOperation = 'overlay';
  
  
  //   ctx.fillStyle = 'rgba(232, 232, 228, 1)';
  //   ctx.beginPath();
  //   ctx.arc(300, 450, 500, 0, Math.PI*2, true); 
  //   ctx.closePath();
  //   ctx.fill();
  
  
  //   ctx.fillStyle = 'rgba(255, 215, 186, .5)';
  //   ctx.beginPath();
  //   ctx.arc(800, 450, 500, 0, Math.PI*2, true); 
  //   ctx.closePath();
  //   ctx.fill();
  // }


  return(

    <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}

    render={data => (
      <>
        <div className="mainContent">
          {children}          
        </div>
        {/* <div className="background">
          <canvas id="canvas"></canvas>
        </div> */}
      </>
    )}
  />

  )

}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout